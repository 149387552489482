<template>
<div>
  <v-data-table
    :headers="headers"
    :items="arreglo"
    :items-per-page="5"
    :search="busqueda"
    dense
    sort-by="id"
    class="elevation-12 mt-5"
    :loading="TablaVacia"
    loading-text="Cargando... Espere por favor"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >

      <v-toolbar-title>{{titulo}}</v-toolbar-title>

        <v-spacer></v-spacer>
        <!--busqueda-->
        <v-text-field
            v-model="busqueda"
            append-icon="mdi-magnify"
            label="BUSQUEDA"
            single-line
            hide-details
        ></v-text-field>
        <!--busqueda-->
        <v-spacer></v-spacer>

          <v-tooltip bottom> 
          <template v-slot:activator="{ on, attrs }">
            <v-checkbox
              v-bind="attrs"
              v-on="on"
              v-model="checkitems"
              @click="ConsultarUsuariosEps"
              color="primary"
              value="red"
              hide-details
              class="mr-2"
            >
            <template v-slot:label>
              <div>
                Vencidas
                  <v-icon>
                    mdi-magnify
                  </v-icon>
              </div>
              </template>
            </v-checkbox>
          </template>
          <span>Click para mostrar cuentas a suspender</span>
        </v-tooltip>

         <!--Popup Disponible-->
        <v-dialog v-model="dialogRenovar" max-width="850px">
          <v-card>

            <v-toolbar
              color="success"
              dark
            >
              <v-spacer></v-spacer>
              RENOVAR AFILIACIÓN SEGURIDAD SOCIAL
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-subtitle class="mt-2">
              <h2 class="text-center">CLIENTE<br> {{Item.nombre_cliente}}</h2>
            </v-card-subtitle>

            <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                
              >
               <v-select
                  disabled
                  v-model="Item.tipo_documento"
                  :items="TipoDocumento"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Tipo Documento Identidad"
                  hint="Indique el tipo de documento de identidad."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="number"
                  v-model="Item.numero_documento"
                  label="Número de documento de identidad *"
                  hint="Indique su número de documento de indentidad"
                  persistent-hint
                  :rules="NameRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.sexo"
                  :items="SEXO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="SEXO?"
                  hint="Indique el sexo de la persona."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="date"
                  v-model="Item.fecha_nacimiento"
                  label="Fecha de nacimiento *"
                  hint="Indique su fecha de nacimiento - la persona debe ser mayor a 18 años"
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.estado_civil"
                  :items="EstadoCivil"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Estado Civil"
                  hint="Indique su estado civil."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.actividad_economica"
                  :items="ActividadEconomica"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '300'}"
                  label="Actividad Economica"
                  hint="Indique su actividad economica."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.eps"
                  :items="EPS"
                  item-text="titulo"
                  item-value="id"
                  menu-props=auto
                  label="EPS"
                  hint="Seleccione su EPS."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.arl"
                  :items="ARL"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="ARL"
                  hint="Seleccione su ARL."
                  persistent-hint
                  :rules="SelectRules"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.caja"
                  :items="CAJA"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="CAJA"
                  hint="Seleccione su Caja."
                  persistent-hint
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.pension"
                  :items="PENSION"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="PENSIÓN"
                  hint="Seleccione su Pensión."
                  persistent-hint
                ></v-select>
                </v-col>

                <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="number"
                  v-model="Item.celular"
                  label="Celular *"
                  hint="Ingrese su número de celular. Ej: 3005206210"
                  :rules="NameRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  disabled
                  type="email"
                  v-model="Item.email"
                  label="Email *"
                  hint="Ingrese su correo electrónico. Ej: tucorreo@gmail.com"
                  :rules="EmailRules"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

                <v-col
                cols="12"
              >
                <v-text-field
                  type="password"
                  v-model="Item.pin"
                  label="Ingrese PIN *"
                  hint="Indique el pin de venta."
                  persistent-hint
                  :rules="pinRules"
                  :counter="4"
                  required
                ></v-text-field>
              </v-col>
              
            </v-row></v-container>

            <v-divider></v-divider>

            <v-card-actions class="mt-2">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialogRenovar = false">Cerrar</v-btn>
              <v-btn color="primary" @click="renovarConfirm">RENOVAR</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--Popup Disponible-->

      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="danger"
          v-bind="attrs"
          v-on="on"
          small
          @click="renovarDialog(item)"
        >
          mdi-history
        </v-icon>
      </template>
      <span>Renovar Afiliación</span>
    </v-tooltip>

    </template>

  </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    components: {
        
    },

    props:['titulo','headers','arreglo'],

    created () {
      this.initialize()
    },

    data: () => ({
      checkitems: false,
      busqueda: '',

      Item: {},
      ItemRenovar: {},
      Accion: null,

      TablaVacia: null,
      items: [],
      
      dialogRenovar: false,
    }),

    watch:{
      arreglo(){
          if(!!this.arreglo){
            if(this.arreglo.lenght > 0){
              this.TablaVacia = true
            }else{
              this.TablaVacia = false
            }
          }
        },
    },

    methods: {
      ...mapMutations([]),
      ...mapActions(['ConsultarUsuariosEps','RenovarUsuarioEps']),

      initialize () {
        
      },

      renovarDialog (item){
        this.Item = item
        var pin = item.pin;
        var id = item.id;
        var cliente = item.cliente;
        var punto_venta = item.punto_venta;
        var riesgo = item.riesgo;
        var eps = item.eps;
        var arl = item.arl;
        var pension = item.pension;
        var caja = item.caja;
        var fecha_vence = item.fecha_vence;
        this.ItemRenovar = {id,pin,cliente,punto_venta,riesgo,eps,arl,pension,caja,fecha_vence}
        this.dialogRenovar = true
      },

      renovarConfirm(){
        this.RenovarUsuarioEps(this.ItemRenovar)
        this.dialogRenovar = false
      }

    },

    computed: {
      ...mapState([]),
      ...mapGetters(['SINO','SEXO','TipoDocumento', 'EstadoCivil', 'ActividadEconomica', 'EPS', 'ARL', 'CAJA', 'PENSION', 'DatosdeUsuario', 'DEPARTAMENTOS', 'CIUDADES']),
    },
  }
</script>